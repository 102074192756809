import React, { FC, useContext } from 'react';

import CustomCarousel from 'components/CustomCarousel';

import ScreenRecognitionContext from '../../../contexts/screenRecognitionContext';
import { ThreePeopleCardProfilesCardsProps } from './models';
import ThreePeopleCardProfilesCard from './ThreePeopleCardProfilesCard';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ThreePeopleCardProfilesCards: FC<ThreePeopleCardProfilesCardsProps> = ({ cards, btn }) => {
  const { isSmallMobile, isMobile } = useContext(ScreenRecognitionContext);

  return isMobile || isSmallMobile ? (
    <CustomCarousel infiniteLoop showArrows={false} showStatus={false} showThumbs={false}>
      {cards.map((slide) => (
        <ThreePeopleCardProfilesCard
          name={slide.name}
          photo={slide.image}
          position={slide.jobTitle}
          link={slide.url}
          btn={btn}
          key={slide.name}
        />
      ))}
    </CustomCarousel>
  ) : (
    <>
      {cards.map((slide) => (
        <ThreePeopleCardProfilesCard
          name={slide.name}
          photo={slide.image}
          position={slide.jobTitle}
          link={slide.url}
          btn={btn}
          key={slide.name}
        />
      ))}
    </>
  );
};

export default ThreePeopleCardProfilesCards;
