import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';

import { ContentBlockProps } from './models';

import './ContentBlock.scss';

const ContentBlock: FC<ContentBlockProps> = ({ module }) => {
  const { subheading, heading, introText, mainText, button } = module;

  return (
    <section
      className={classNames('content-block section rte-white', {
        'content-block--left-aligned': module.isLeftAligned,
      })}
    >
      <Container>
        <Row>
          <Col xl={module.isLeftAligned ? '8' : '10'} sm="12">
            {subheading ? <span className="content-block__subtitle h6">{subheading}</span> : null}
            {heading
              ? React.createElement(
                  `${module.headingStyle === 'h2-h3' ? 'h2' : module.headingStyle || 'h2'}`,
                  {
                    className: `content-block__title ${
                      module.headingStyle === 'h2-h3' ? 'h3' : module.headingStyle || 'h2'
                    }`,
                  },
                  heading
                )
              : null}
            {introText ? (
              <div
                dangerouslySetInnerHTML={{ __html: introText }}
                className="content-block__info standfirst rte"
              />
            ) : null}
            {mainText ? (
              <div
                dangerouslySetInnerHTML={{ __html: mainText }}
                className="content-block__main rte"
              />
            ) : null}
            <LinkItem
              link={button}
              linkClass="content-block__cta btn btn--pink"
              hasIcon
              isLink
              showTitle
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentBlock;
