import React, { FC } from 'react';

import ColourfulQuoteSlider from 'components/ColourfulQuoteSlider';
import ContentBlock from 'components/ContentBlock';
import CtaCards from 'components/CtaCards';
import FullWidthVideo from 'components/FullWidthVideo';
import HeroQuote from 'components/HeroQuote';
import ThreePeopleCardProfiles from 'components/ThreePeopleCardProfiles';
import VideoAndImageWithText from 'components/VideoAndImageWithText';

import { PeopleProfileBodyRendererProps } from './models';

const Components = {
  'CTA Cards': CtaCards,
  'Full Width Video': FullWidthVideo,
  'Three People Card Profiles': ThreePeopleCardProfiles,
  'Video And Image With Text': VideoAndImageWithText,
  'Colourful Quote Slider': ColourfulQuoteSlider,
  'Hero Quote': HeroQuote,
  'Content Block': ContentBlock,
};

const PeopleProfileBodyRenderer: FC<PeopleProfileBodyRendererProps> = ({ modules }) => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      return <Component key={`${block.structure}${index}`} module={block.module} />;
    })}
  </>
);

export default PeopleProfileBodyRenderer;
