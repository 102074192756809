import React, { FC } from 'react';

import { Col, Container, Row } from 'layout';
import ContactSocialMedia from 'common/ContactSocialMedia';
import PeoplePreviewProfileImage from 'common/images/PeoplePreviewProfileImage';

import { BoardBiographyProps } from './models';

import './BoardBiography.scss';

const BoardBiography: FC<BoardBiographyProps> = ({
  profileImage,
  bioTextIntro,
  bioTextMain,
  connectWithText,
  socialNetworkLink,
  name,
}) => (
  <section className="board-biography section rte-white">
    <Container>
      <Row>
        <Col xl="4" md="4" sm="12" className="col--no-gutters">
          {profileImage ? (
            <PeoplePreviewProfileImage
              imageData={profileImage}
              alt={name}
              className="board-biography__image"
            />
          ) : null}
        </Col>
        <Col xl="8" md="8" sm="12" className="col--no-gutters">
          {bioTextIntro ? (
            <div
              className="board-biography__intro-text standfirst rte"
              dangerouslySetInnerHTML={{ __html: bioTextIntro }}
            />
          ) : null}
          {bioTextMain ? (
            <div
              className="board-biography__main-text rte"
              dangerouslySetInnerHTML={{ __html: bioTextMain }}
            />
          ) : null}
          {socialNetworkLink.length > 0 && socialNetworkLink[0] ? (
            <ContactSocialMedia followBrandText={connectWithText} socialLinks={socialNetworkLink} />
          ) : null}
        </Col>
      </Row>
    </Container>
  </section>
);

export default BoardBiography;
