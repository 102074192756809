import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { ImagesContent } from '../../../@types/content/images';
import { getArtDirectedPeoplePreviewProfileImages } from './utils';

const defaultBreakpoints: ImagesContent.PeoplePreviewProfileImageBreakpoint[] = [
  { alias: 'peopleProfileMobile', media: '(max-width: 767px)' },
  { alias: 'peopleProfileTablet', media: '(max-width: 1199px)' },
];

const PeoplePreviewProfileImage: FC<ImagesContent.PeoplePreviewProfileImageProps> = ({
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  const isArtDirected = Object.keys(imageData).includes('peopleProfileDesktop');
  const mainImage = isArtDirected
    ? getImage((imageData as ImagesContent.PeoplePreviewProfileImage).peopleProfileDesktop)
    : getImage(imageData as ImagesContent.ImageDataLike);

  const images = withArtDirection(
    mainImage as IGatsbyImageData,
    getArtDirectedPeoplePreviewProfileImages(
      breakpoints,
      imageData as ImagesContent.PeoplePreviewProfileImage
    )
  );

  return <GatsbyImage image={images} alt={alt} {...restProps} />;
};

export const createImageWithBreakpoints =
  (
    breakpoints: ImagesContent.PeoplePreviewProfileImageBreakpoint[]
  ): FC<ImagesContent.PeoplePreviewProfileImageProps> =>
  (props: ImagesContent.PeoplePreviewProfileImageProps) =>
    <PeoplePreviewProfileImage {...props} breakpoints={breakpoints} />;

export default PeoplePreviewProfileImage;
