import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

import { ImagesContent } from '../../../@types/content/images';
import { getArtDirectedPeoplePreviewCardImages } from './utils';

const defaultBreakpoints: ImagesContent.PeoplePreviewCardImageBreakpoint[] = [
  { alias: 'peopleCardMobile', media: '(max-width: 767px)' },
  { alias: 'peopleCardTablet', media: '(max-width: 1199px)' },
];

const PeoplePreviewCardImage: FC<ImagesContent.PeoplePreviewCardImageProps> = ({
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  const isArtDirected = Object.keys(imageData).includes('peopleCardDesktop');
  const mainImage = isArtDirected
    ? getImage((imageData as ImagesContent.PeoplePreviewCardImage).peopleCardDesktop)
    : getImage(imageData as ImagesContent.ImageDataLike);

  const images = withArtDirection(
    mainImage as IGatsbyImageData,
    getArtDirectedPeoplePreviewCardImages(
      breakpoints,
      imageData as ImagesContent.PeoplePreviewCardImage
    )
  );

  return <GatsbyImage image={images} alt={alt} {...restProps} />;
};

export const createImageWithBreakpoints =
  (
    breakpoints: ImagesContent.PeoplePreviewCardImageBreakpoint[]
  ): FC<ImagesContent.PeoplePreviewCardImageProps> =>
  (props: ImagesContent.PeoplePreviewCardImageProps) =>
    <PeoplePreviewCardImage {...props} breakpoints={breakpoints} />;

export default PeoplePreviewCardImage;
