import React, { FC } from 'react';

import { Col, Container, Row } from 'layout';
import LinkItem from 'common/LinkItem';

import { ThreePeopleCardProfilesProps } from './models';
import ThreePeopleCardProfilesCards from './ThreePeopleCardProfilesCards';

import './ThreePeopleCardProfiles.scss';

const ThreePeopleCardProfiles: FC<ThreePeopleCardProfilesProps> = ({ module }) => (
  <section className="three-people-card-porfiles section-bg">
    <Container>
      <Row>
        <Col xl="4" md="12" sm="12" className="three-people-card-porfiles__content col--no-gutters">
          <span className="three-people-card-porfiles__sub-title caption">{module.pretitle}</span>
          <h2 className="three-people-card-porfiles__title h4">{module.title}</h2>
          {module.text ? <p className="three-people-card-porfiles__desc">{module.text}</p> : null}
          <LinkItem
            link={module.ctaLink}
            linkClass="three-people-card-porfiles__link link link--black-white"
            titleClass="three-people-card-porfiles__link-text"
            hasIcon
            isLink
            showTitle
          />
        </Col>
        <Col
          xl="8"
          md="12"
          sm="12"
          className="three-people-card-porfiles__cards-container col--no-gutters"
        >
          <ThreePeopleCardProfilesCards cards={module.cards} btn={module.viewProfileButtonText} />
        </Col>
      </Row>
    </Container>
  </section>
);

export default ThreePeopleCardProfiles;
