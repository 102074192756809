import React, { FC, useContext } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import PeoplePreviewCardImage from 'common/images/PeoplePreviewCardImage';

import Arrow from '../../../../common/icons/arrow-right.svg';
import ScreenRecognitionContext from '../../../../contexts/screenRecognitionContext';
import { ThreePeopleCardProfilesCardProps } from './models';

import './ThreePeopleCardProfilesCard.scss';

const ThreePeopleCardProfilesCard: FC<ThreePeopleCardProfilesCardProps> = ({
  photo,
  name,
  position,
  link,
  btn,
}) => {
  const { isSmallMobile, isMobile } = useContext(ScreenRecognitionContext);

  const nameClasses = classNames('three-people-card-porfiles__name', {
    h6: !isMobile && !isSmallMobile,
  });
  const positionClasses = classNames('three-people-card-porfiles__position', {
    caption: !isMobile && !isSmallMobile,
  });

  return (
    <div className="three-people-card-porfiles__cards">
      <PeoplePreviewCardImage
        imageData={photo}
        alt={name}
        className="three-people-card-porfiles__photo"
      />
      <h3 className={nameClasses}>{name}</h3>
      {position ? <span className={positionClasses}>{position}</span> : null}
      <Link
        to={link}
        className="three-people-card-porfiles__link link link--black-white"
        data-testid={link}
      >
        <span className="three-people-card-porfiles__link-text">{btn}</span>
        <span className="three-people-card-porfiles__link-text--hidden">
          {`of ${name} working as ${position}`}
        </span>
        <Arrow aria-hidden="true" focusable="false" />
      </Link>
    </div>
  );
};

export default ThreePeopleCardProfilesCard;
